import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika-konfidencijnosti"} />
		<Helmet>
			<title>
				Tattoo Nexus
			</title>
			<meta name={"description"} content={"Від мінімалістичних мотивів до вишуканих композицій – ми пропонуємо повний спектр стилів, щоб створити татуювання вашої мрії."} />
			<meta property={"og:title"} content={"Tattoo Nexus"} />
			<meta property={"og:description"} content={"Від мінімалістичних мотивів до вишуканих композицій – ми пропонуємо повний спектр стилів, щоб створити татуювання вашої мрії."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0 60px 0" background="--color-darkL1" color="--light" quarkly-title="PrivacyPolicy">
			<Override slot="SectionContent" flex-direction="column" align-items="center" text-align="center" />
			<Text as="h1" font="--headline2" margin="0px 0px 24px 0px">
				Політика конфіденційності
			</Text>
			<Text font="--lead" margin="0px 0px 48px 0px" color="--lightD2" max-width="800px">
				Ця політика конфіденційності пояснює, як Tattoo Nexus збирає, використовує та захищає вашу особисту інформацію, коли ви користуєтесь нашими послугами або відвідуєте наш вебсайт. Ми ставимося до вашої приватності серйозно і зобов’язуємось забезпечити конфіденційність ваших даних.
			</Text>
			<Box width="100%" max-width="800px" margin="0 auto" text-align="left">
				<Text as="h2" font="--headline3" margin="24px 0px">
					1. Інформація, яку ми збираємо
				</Text>
				<Text font="--base" color="--lightD2">
					Ми можемо збирати та обробляти такі дані:
					<Text as="p" margin="16px 0 16px 0">
						1.1. Ім’я, контактна інформація (електронна пошта, номер телефону), яку ви надаєте при бронюванні сеансу або під час реєстрації на наші послуги.
					</Text>
					<Text as="p" margin="16px 0 16px 0">
						1.2. Інформація про ваші сеанси, включаючи історію бронювань і послуги, які ви замовляли.
					</Text>
					<Text as="p" margin="16px 0 16px 0">
						1.3. Технічна інформація, така як IP-адреса, дані браузера та файли cookie, коли ви відвідуєте наш вебсайт.
					</Text>
				</Text>
				<Text as="h2" font="--headline3" margin="24px 0px">
					2. Використання інформації
				</Text>
				<Text font="--base" color="--lightD2">
					Ми використовуємо вашу інформацію для:
					<Text as="p" margin="16px 0 16px 0">
						2.1. Організації сеансів татуювання та надання персоналізованих послуг.
					</Text>
					<Text as="p" margin="16px 0 16px 0">
						2.2. Покращення вашого досвіду користування нашими послугами, включаючи надсилання оновлень про нові послуги або спеціальні пропозиції.
					</Text>
					<Text as="p" margin="16px 0 16px 0">
						2.3. Аналізу відвідуваності нашого вебсайту для оптимізації його функціоналу.
					</Text>
				</Text>
				<Text as="h2" font="--headline3" margin="24px 0px">
					3. Захист ваших даних
				</Text>
				<Text font="--base" color="--lightD2">
					Ми застосовуємо відповідні заходи безпеки для захисту вашої інформації від несанкціонованого доступу, зміни або розкриття.
				</Text>
				<Text as="h2" font="--headline3" margin="24px 0px">
					4. Обмін інформацією
				</Text>
				<Text font="--base" color="--lightD2">
					Ми не продаємо та не розповсюджуємо вашу особисту інформацію третім сторонам без вашої згоди, за винятком випадків, коли це необхідно для надання послуг або якщо це вимагається законом.
				</Text>
				<Text as="h2" font="--headline3" margin="24px 0px">
					5. Ваші права
				</Text>
				<Text font="--base" color="--lightD2">
					Ви маєте право на доступ до ваших особистих даних, їх коригування або видалення. Якщо ви хочете скористатися цими правами, зв'яжіться з нами за електронною поштою: privacy@tattoonexus.com.
				</Text>
				<Text as="h2" font="--headline3" margin="24px 0px">
					6. Політика щодо файлів cookie
				</Text>
				<Text font="--base" color="--lightD2">
					Наш вебсайт використовує файли cookie для забезпечення безперебійної роботи та поліпшення вашого досвіду. Ви можете керувати налаштуваннями файлів cookie через ваш браузер.
				</Text>
				<Text as="h2" font="--headline3" margin="24px 0px">
					7. Зміни до політики конфіденційності
				</Text>
				<Text font="--base" color="--lightD2">
					Ми можемо оновлювати цю політику конфіденційності, щоб відобразити зміни в наших практиках або законодавчих вимогах. Просимо вас періодично перевіряти цю сторінку для ознайомлення з оновленнями.
				</Text>
				<Text as="h2" font="--headline3" margin="24px 0px">
					8. Контактна інформація
				</Text>
				<Text font="--base" color="--lightD2">
					Якщо у вас є будь-які питання щодо цієї політики конфіденційності або наших практик захисту даних, зв'яжіться з нами за електронною поштою: privacy@tattoonexus.com.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<Components.Privacy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});